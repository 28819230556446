const COLOR_OPTION = {
  SUCCESS: '#4FD600',
  ERROR: '#FF6969',
  NOT_FOUND: '#ACACAC',
  WARNING: '#FFAB5E',
}

export const SUSPENDED_CONTRACT_STATUS_COLOR = COLOR_OPTION.ERROR

export const CONNECTION_STATUS_NAME = {
  ONLINE: 'ONLINE',
  OFFLINE: 'OFFLINE',
  SUSPENDED: 'SUSPENDED',
  ENERGY_FAILURE: 'ENERGY_FAILURE',
  NO_ENERGY: 'NO_ENERGY',
  ENERGY_BLACKOUT: 'ENERGY_BLACKOUT',
  ENERGY_LOSS: 'ENERGY_LOSS',
  UNKNOWN_EQUIPMENT: 'UNKNOWN_EQUIPMENT',
  COMMUNICATION_FAILURE: 'COMMUNICATION_FAILURE',
  BAD_SIGNAL: 'BAD_SIGNAL',
  SIGNAL_DYING: 'SIGNAL_DYING',
  UNKNOWN: 'UNKNOWN',
}

export const CONNECTION_STATUS_COLORS = {
  [CONNECTION_STATUS_NAME.ONLINE]: COLOR_OPTION.SUCCESS,
  [CONNECTION_STATUS_NAME.OFFLINE]: COLOR_OPTION.ERROR,
  [CONNECTION_STATUS_NAME.SUSPENDED]: COLOR_OPTION.ERROR,
  [CONNECTION_STATUS_NAME.ENERGY_FAILURE]: COLOR_OPTION.WARNING,
  [CONNECTION_STATUS_NAME.NO_ENERGY]: COLOR_OPTION.WARNING,
  [CONNECTION_STATUS_NAME.ENERGY_BLACKOUT]: COLOR_OPTION.WARNING,
  [CONNECTION_STATUS_NAME.ENERGY_LOSS]: COLOR_OPTION.NOT_FOUND,
  [CONNECTION_STATUS_NAME.UNKNOWN_EQUIPMENT]: COLOR_OPTION.NOT_FOUND,
  [CONNECTION_STATUS_NAME.COMMUNICATION_FAILURE]: COLOR_OPTION.NOT_FOUND,
  [CONNECTION_STATUS_NAME.BAD_SIGNAL]: COLOR_OPTION.NOT_FOUND,
  [CONNECTION_STATUS_NAME.SIGNAL_DYING]: COLOR_OPTION.NOT_FOUND,
  [CONNECTION_STATUS_NAME.UNKNOWN]: COLOR_OPTION.NOT_FOUND,
}

export const DEFAULT_CONNECTION_STATUS_COLOR = COLOR_OPTION.NOT_FOUND
