import React from 'react'
import { TV_PLAN_CARDS } from 'app-constants'
import { TvPlanCard } from '../tv-plan'
import './style.scss'

export function GigaTvCard({ disabled }) {
  async function handleClick(tvPlan) {
    if (tvPlan && tvPlan.urlDocument) {
      return window.open(tvPlan.urlDocument, '_blank').focus()
    }
  }

  return (
    <TvPlanCard
      disabled={disabled}
      tvPlanName={TV_PLAN_CARDS.GIGA_TV}
      handleClick={handleClick}
      buttonLabel='IR PARA O SITE'
    />
  )
}
