import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { useForm, getCallsHistory, useFirebaseEvent } from 'hooks'
import { formatDate, formatPhone, subtractDays, toISO } from 'utils'
import { FIREBASE_EVENTS } from 'app-constants'
import { Card } from 'components/card'
import { Input } from 'components/input'
import { Button } from 'components/button'
import { ContextLoader } from 'components/context-loader'
import { setDrawerAlert } from 'slices/drawer'
import { STATICS } from 'statics'
import { CALLS_HISTORY_ERROR } from '../../constants'
import './style.scss'

const validationSchema = Yup.object().shape({
  initialDate: Yup.string().required(),
  endDate: Yup.string().required(),
})

export function CallsHistory() {
  const [calls, setCalls] = useState([])
  const [loading, setLoading] = useState(false)
  const { sendEvent } = useFirebaseEvent()
  const dispatch = useDispatch()
  const {
    selected: { id },
  } = useSelector(state => state.contract)
  const form = useForm({
    initialValues: {
      initialDate: formatDate(subtractDays(new Date(), 30)),
      endDate: formatDate(new Date()),
    },
    validationSchema,
    onSubmit: handleSubmit,
  })

  useEffect(() => {
    loadCallsHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    sendEvent(FIREBASE_EVENTS.TELEPHONY_CALL_HISTORY)
  }, [sendEvent])

  async function loadCallsHistory() {
    try {
      setLoading(true)
      const { initialDate, endDate } = form.values
      const isoInitialDate = toISO(initialDate)
      const isoEndDate = toISO(endDate)
      const { data } = await getCallsHistory(id, isoInitialDate, isoEndDate)

      setCalls(data)
    } catch (err) {
      console.error(err)
      dispatch(setDrawerAlert({ severity: 'error', title: CALLS_HISTORY_ERROR }))
    } finally {
      setLoading(false)
    }
  }

  async function handleSubmit() {
    loadCallsHistory()
  }

  function renderWithoutCalls() {
    return (
      <>
        <STATICS.INVOICES_HISTORIC className='icon' />
        <p className='message'>Não foram encontrados registros para o período selecionado.</p>
      </>
    )
  }

  function renderCalls() {
    return calls.map((call, index) => {
      const { phoneNumber, callDate } = call
      const formattedPhoneNumber = formatPhone(phoneNumber.slice(2))
      const formattedDate = formatDate(callDate)

      return (
        <Card className='card' key={String(index)}>
          <p className='text'>{formattedPhoneNumber}</p>
          <p className='date'>{formattedDate}</p>
        </Card>
      )
    })
  }

  if (loading) return <ContextLoader fixed={false} />

  return (
    <div className='calls-history-component'>
      <h3 className='title'>Histórico de ligações</h3>
      <p className='text'>Filtrar por período</p>
      <form className='form' onSubmit={form.handleSubmit}>
        <Input
          className='input'
          name='initialDate'
          label='Data inicial'
          placeholder='Data inicial'
          onChange={form.handleChange}
          value={form.values.initialDate}
          mask='99/99/9999'
          hasError={form.errors.initialDate}
          size='small'
        />
        <p className='text'>a</p>
        <Input
          className='input'
          name='endDate'
          label='Data final'
          placeholder='Data final'
          onChange={form.handleChange}
          value={form.values.endDate}
          mask='99/99/9999'
          hasError={form.errors.endDate}
          size='small'
        />
        <Button type='submit' className='button' color='primary' outlined size='sm'>
          Filtrar
        </Button>
      </form>
      {calls.length ? renderCalls() : renderWithoutCalls()}
    </div>
  )
}
